import React from 'react'
import './services.css'
import { BiCheck } from 'react-icons/bi'

const Services = () => {
  return (
    <section id='services'>
      <h5>What we can do:</h5>
      <h2>Services</h2>

      {/* <div className='text-box'>
  <p className='text-light'><strong><span style={{ fontSize: '22px' }}>Safety Check UK </span></strong>
    offers a comprehensive range of services tailored to meet the unique needs of each client.
    Our approach is flexible and client-centric, allowing us to craft personalized solutions
    that prioritize safety, compliance, and efficiency.
    With expertise across various domains, we strive to adapt and innovate to address the specific
    challenges faced by our clients.
    Although our capabilities are diverse, we understand that every project is unique,
    and we encourage clients to reach out even if a particular service is not listed.
    From ensuring regulatory compliance to optimizing operational processes,
    Safety Check UK is your trusted partner for all your safety and compliance needs.</p>
</div> */}


      <div className='container services_container'>
      <article className='service'>
  <div className='service_head'>
    <h3>Plant Hire</h3>
  </div>

  <ul className='service_list'>
    <li>
      <BiCheck className='service_list-icon' />
      <p>Access Equipment</p>
    </li>
    <li>
      <BiCheck className='service_list-icon' />
      <p>Skip Hire</p>
    </li>
    <li>
      <BiCheck className='service_list-icon' />
      <p>Breaking and Drilling</p>
    </li>
    <li>
      <BiCheck className='service_list-icon' />
      <p>Concreting</p>
    </li>
    <li>
      <BiCheck className='service_list-icon' />
      <p>Safety Equipment</p>
    </li>
    <li>
      <BiCheck className='service_list-icon' />
      <p>Ventilation and Extraction Equipment</p>
    </li>
    <li>
      <BiCheck className='service_list-icon' />
      <p>Welding Equipment</p>
    </li>
  </ul>
</article>

        {/* END OF PLANT HIRE */}

        <article className='service'>
  <div className='service_head'>
    <h3>Facility Management</h3>
  </div>

  <ul className='service_list'>
    <li>
      <BiCheck className='service_list-icon' />
      <p>Electrical Servicing</p>
    </li>
    <li>
      <BiCheck className='service_list-icon' />
      <p>Plumbing</p>
    </li>
    <li>
      <BiCheck className='service_list-icon' />
      <p>HVAC</p>
    </li>
    <li>
      <BiCheck className='service_list-icon' />
      <p>Lift Servicing</p>
    </li>
    <li>
      <BiCheck className='service_list-icon' />
      <p>Ground Maintenance</p>
    </li>
    <li>
      <BiCheck className='service_list-icon' />
      <p>Cleaning</p>
    </li>
    <li>
      <BiCheck className='service_list-icon' />
      <p>Gas Servicing</p>
    </li>
  </ul>
</article>

        {/* END OF FACILITY MANAGEMENT */}

        <article className='service'>
  <div className='service_head'>
    <h3>Compliance Services</h3>
  </div>

  <ul className='service_list'>
    <li>
      <BiCheck className='service_list-icon' />
      <p>PAT Testing</p>
    </li>
    <li>
      <BiCheck className='service_list-icon' />
      <p>Emergency Lights Testing</p>
    </li>
    <li>
      <BiCheck className='service_list-icon' />
      <p>EICR</p>
    </li>
    <li>
      <BiCheck className='service_list-icon' />
      <p>Fire Extinguisher Servicing</p>
    </li>
    <li>
      <BiCheck className='service_list-icon' />
      <p>Fire Risk Assessment</p>
    </li>
    <li>
      <BiCheck className='service_list-icon' />
      <p>Fire Alarm Maintenance</p>
    </li>
  </ul>
</article>

        {/* END OF COMPLIANCE SERVICES */}

      </div>
    </section>
  )
}

export default Services