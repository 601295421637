import React from 'react'
import './footer.css'
import { FaXTwitter } from 'react-icons/fa6'
import { AiFillLinkedin } from 'react-icons/ai'
import { AiFillGithub } from 'react-icons/ai'

const Footer = () => {
  return (
    <footer>

      {/* <ul className='permalinks'>
        <li><a href='#'>Home</a></li>
        <li><a href='#about'>About</a></li>
        <li><a href='#services'>Services</a></li>
        <li><a href='#contact'>Contact</a></li>
      </ul> */}

      <a href='#' className='footer_logo'>Safety Check UK Limited</a>

      <p className='permalinks'>
        61 Bridge Street<br />
        Kington<br />
        HR5 3DJ<br />
      </p>
      <p className='permalinks'>Company Number: 15529115</p>
      <p className='permalinks_two'>
  Copyright &copy; 2024 Safety Check UK Limited. All Rights Reserved.
</p>
<a href='https://evolv.org.uk/' target='_blank' className='permalinks_two'>
  <strong><span>Website built by: eVOLV</span></strong>
</a>

    </footer>
  )
}

export default Footer
