import React, { useEffect } from 'react';
import Header from './components/header/Header';
import Nav from './components/nav/Nav';
import About from './components/about/About';
import Experience from './components/experience/Experience';
import Services from './components/services/Services';
import Portfolio from './components/portfolio/Portfolio';
import Testimonials from './components/tesimonials/Testimonials';
import Contact from './components/contact/Contact';
import WalletDonation from './components/payment/Payment';
import Footer from './components/footer/Footer';

const App = () => {
  // Set the document title when the component mounts
  useEffect(() => {
    document.title = 'Safety Check UK Ltd';
  }, []);

  return (
    <div>
      <>
        <Header />
        <Nav />
        <About />
        <Services />
        <Contact />
        {/* <Portfolio />
        <Experience />
        <Testimonials />
        <WalletDonation /> */}
        <Footer />
      </>
    </div>
  );
};

export default App;
