import React from 'react'
import './contact.css'
import { MdOutlineEmail } from 'react-icons/md'
import { AiOutlineLinkedin } from 'react-icons/ai'
import { BsWhatsapp } from 'react-icons/bs'
import { FiPhone } from "react-icons/fi";

const Contact = () => {
  return (
    <section id='contact'>
      <h5>Get In Touch</h5>
      <h2>Contact</h2>

      <div className='container contact_container'>
        <div className='contact_options'>
          <article className='contact_option'>
            <MdOutlineEmail className='contact_option-icon' />
            <h4>Email</h4>
            <h5></h5>
            <a href='mailto:info@safetycheckuk.com' target='_blank'>info@safetycheckuk.com</a>
          </article>
          <article className='contact_option'>
            <AiOutlineLinkedin className='contact_option-icon' />
            <h4>Find us on Linkedin</h4>
            <h5></h5>
            <a href='https://www.linkedin.com/company/safety-check-uk-limited/' target='_blank'>Send a message</a>
          </article>
          <article className='contact_option'>
            <BsWhatsapp className='contact_option-icon' />
            <h4>WhatsApp</h4>
            <h5>07729835184</h5>
            <a href='https://api.whatsapp.com/send?phone=07729835184' target='_blank'>Send a message</a>
          </article>
            <article className='contact_option'>
            <FiPhone className='contact_option-icon' />
            <h4>Call</h4>
            <h5>01432655063</h5>
            <a href='https://api.whatsapp.com/send?phone=07729835184' target='_blank'>Send a message</a>
          </article>
        </div>
      </div>
    </section>
  )
}

export default Contact
