import React from 'react';
import './about.css';
import { FaAward } from 'react-icons/fa';
import { AiOutlineUserAdd } from 'react-icons/ai';
import { VscFolderLibrary } from 'react-icons/vsc';

const About = () => {
  return (
    <section id='about'>
      <h5>Get To Know...</h5>
      <h2>Safety Check UK Limited</h2>

      <div className='about_content'>
        <div className='about_cards'>
          <article className='about_card'>
            <FaAward className='about_icon' />
            <h5>Experience</h5>
            <small>10+ Years</small>
          </article>

          <article className='about_card'>
            <AiOutlineUserAdd className='about_icon' />
            <h5>Partnerships</h5>
            <small>10+ Worldwide</small>
          </article>

          <article className='about_card'>
            <VscFolderLibrary className='about_icon' />
            <h5>Projects</h5>
            <small>10+ Completed</small>
          </article>
        </div>
        <br />

<p style={{ textAlign: 'center', display: 'block', width: 'fit-content', margin: '0 auto', padding: '0 50px' }}>
  At <strong><span style={{ fontSize: '22px' }}>Safety Check UK</span></strong>, we specialize in providing comprehensive solutions in plant hire, facility management, compliance, and other essential services. With a commitment to excellence, we tackle complex challenges to deliver innovative and tailored solutions that empower businesses and individuals alike.
</p>
<br />
<p style={{ textAlign: 'center', display: 'block', width: 'fit-content', margin: '0 auto', padding: '0 50px' }}>
  We take pride in offering a wide range of services, including plant hire, which encompasses essentials like skips, concrete mixers, breaking, and drilling. Our facility management services cover electrical servicing, plumbing, maintenance, and more. Additionally, our compliance services include PAT testing and fire risk assessment, ensuring regulatory adherence and safety compliance.
</p>
<br />
<p style={{ textAlign: 'center', display: 'block', width: 'fit-content', margin: '0 auto', padding: '0 50px' }}>
We understand that every project is unique so we encourage clients to reach out even if a particular service is not listed.
</p>
<br />
<p style={{ textAlign: 'center', display: 'block', width: 'fit-content', margin: '0 auto', padding: '0 50px' }}>
  Partner with <strong><span style={{ fontSize: '22px' }}>Safety Check UK</span></strong> today and unlock your full potential. Let us help you navigate the intricacies of plant hire, facility management, compliance, and beyond, empowering your business for success.
</p>

        <br />
        <a href='#contact' className='btn' style={{ textAlign: 'center', display: 'block', width: 'fit-content', margin: '0 auto' }}>Let's Talk</a>
      </div>
    </section>
  );
}

export default About;
